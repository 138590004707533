
import Vue from "vue";
import store from "@/store";

import pieChartConfig from "../config/pie-chart-config.json";
import barChartConfig from "../config/bar-chart-config.json";
import lineChartConfig from "../config/line-chart-config.json";

export default Vue.extend({
  name: "work-positions",

  data: () => ({
    pieChartConfig,
    barChartConfig,
    lineChartConfig,
    headers: [] as Array<HeadersInterface>,
    items: [] as Array<any>,
    selects: {
      users: []
    } as any,
    editorVariables: [] as Array<string>,
    userToSendEmailTo: null as number | null,
    openedTemplate: null as number | null,
    sheet: false as boolean,
    loading: false as boolean
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      next();
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {}
});
